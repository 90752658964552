
import Navbar2 from "../components/navbar2";
import Stakes from "../components/stake";

const Stake = () => {
    return(
        <div className="bg-palette-color-1">
            <Navbar2/>
            <Stakes/>
        </div>
    )
}

export default Stake;