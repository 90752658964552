import React from 'react';
import Decentralized from "../assets/decentralized.png"
import AIAPI from "../assets/aiapiendpoints.png"
import Revenue from "../assets/revenue.png"
import RentAI from "../assets/rentainodes.png"
import Lend from "../assets/lendgpu.png"
import Ondemand from "../assets/ondemandnodes.png"

const Features = () => {
  return (
    <div className='bg-palette-color-5 p-8 lg:p-40 text-white' id='features'>
      <p className='text-palette-color-3 text-lg'>FEATURES</p>
      <h1 className='text-4xl leading-loose font-bold mb-20'>KEY FEATURES</h1>
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
        <div className='text-left mt-20'>
          <img src={Decentralized} className="w-28" alt='Decentralized'/>
          <p className='text-xl leading-loose mt-5 font-bold'>DECENTRALIZED</p>
          <p className="leading-8 text-gray-400 font-bold">Decentralized AI node ecosytem that allows anyone to participate and earn $GPU</p>
        </div>
        <div className='text-left mt-20'>
        <img src={AIAPI} className="w-28" alt='AiApi'/>
          <p className='text-xl leading-loose mt-5 font-bold'>AI API ENDPOINTS</p>
          <p className="leading-8 text-gray-400 font-bold">Readily available AI image, video and other enterprise api endpoints that can be easily integrate into any system</p>
        </div>
        <div className='text-left mt-20'>
        <img src={Revenue} className="w-28" alt='Revenue'/>
          <p className='text-xl leading-loose mt-5 font-bold'>REVENUE SHARING</p>
          <p className="leading-8 text-gray-400 font-bold">Stake $GPU and earn a share of revenue earned by the NODE AI ecosystem</p>
        </div>
        <div className='text-left mt-20'>
        <img src={RentAI} className="w-28" alt='RentAI'/>
          <p className='text-xl leading-loose mt-5 font-bold'>RENT AI NODES</p>
          <p className="leading-8 text-gray-400 font-bold">Rent readily available AI servers with for your own AI use cases and pay a monthly fee</p>
        </div>
        <div className='text-left mt-20'>
        <img src={Lend} className="w-28" alt='Lendgpu'/>
          <p className='text-xl leading-loose mt-5 font-bold'>LEND GPU POWER</p>
          <p className="leading-8 text-gray-400 font-bold">Lend your inactive gpus and earn $GPU based on the usage by the renter</p>
        </div>
        <div className='text-left mt-20'>
        <img src={Ondemand} className="w-28" alt='Ondemand'/>
          <p className='text-xl leading-loose mt-5 font-bold'>ON DEMAND NODES</p>
          <p className="leading-8 text-gray-400 font-bold">Use an AI server on demand and pay by the hour. Mainly used for onetime use cases</p>
        </div>
      </div>
    </div>
  );
};

export default Features;
