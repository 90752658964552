import React from 'react';

const Tokenomics = () => {
  return (
    <div className='bg-palette-color-4 p-8 lg:p-40 text-white'>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
        <div className='text-left'>
          <p className='text-4xl font-bold text-palette-color-3'>5</p>
          <p className='text-lg'>Active Nodes</p>
        </div>
        <div className='text-left'>
          <p className='text-4xl font-bold text-palette-color-3'>21</p>
          <p className='text-lg'>Total GPUs</p>
        </div>
        <div className='text-left'>
          <p className='text-4xl font-bold text-palette-color-3'>$15,750</p>
          <p className='text-lg'>Avg Monthly Revenue</p>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
