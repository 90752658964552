import React, { useState, useEffect } from "react";
import logoImage from "../assets/logo.png";
import wallet from "../assets/wallet.png"
const Navbar2 = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`sticky top-0 z-50 transition duration-300 ${isScrolled || isMenuOpen ? "bg-palette-color-2" : "bg-palette-color-1"
        }`}
    >
   
      <div className="container text-md flex items-center justify-between px-14 py-2 mx-auto">
        
        <a href="#">
          <img src={logoImage} alt="Logo" className="w-48 h-auto" />
        </a>
        <div className="hidden text-base font-normal space-x-12 text-white md:flex">
          <a
            href="/"
            className="hover:text-palette-color-3"
          >
            Home
          </a>
          <a
            href="/nodes"
            className="hover:text-palette-color-3"
          >
            Live Nodes
          </a>
          <a
            href="/stake"
            className="hover:text-palette-color-3"
          >
            Stake
          </a>
          <a
            href="https://docs.nodeai.art/introduction/overview"
            className="hover:text-palette-color-3"
          >
            Docs
          </a>
        </div>
        <div className="items-center hidden space-x-6 md:flex">
          <a
            href="#"
            className="flex gap-1 px-14 py-3 text-lg transition duration-300 bg-white text-black "
          >
            <img src={wallet} alt="wallet"/>
            Connect
          </a>
        </div>
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className=" focus:outline-none"
            aria-expanded={isMenuOpen} 
            aria-controls="mobile-menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>
      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="py-2 text-white md:hidden">

          <a
            href="#features"
            className="block px-4 py-2 "
          >
            FEATURES
          </a>
          <a
            href="#pricing"
            className="block px-4 py-2 "
          >
            PRICING
          </a>
          <a
            href="#roadmap"
            
            className="block px-4 py-2 "
          >
            ROADMAP
          </a>
          <a
            href="#tokenomics"
            className="block px-4 py-2 "
          >
            TOKENOMICS
          </a>
          <a
            href="#faq"
            className="block px-4 py-2 "
          >
            FAQ
          </a>
          <a
            href="#socials"
            className="block px-4 py-2 "
          >
            SOCIALS
          </a>
          <a
            href="https://docs.nodeai.art/introduction/overview"
            className="block px-4 py-2 "
          >
            DOCS
          </a>
          {/* <a
            href="#"
            className="block px-2 py-2 rounded-md"
          >
            Customize your Journey
          </a> */}
        </div>
      )}
    </nav>
  );
};

export default Navbar2;
