
import React from 'react';
import SVG from "../assets/chart.png"

const Chart = () => {
  return (
    <div className='bg-palette-color-5 h-full p-8 lg:p-40 text-white'>
    <p className='text-palette-color-3 text-lg'>TOKENOMICS</p>
    <h1 className='text-4xl leading-loose font-bold'>DEFLATIONARY TOKENOMICS</h1>
    <div className="flex flex-col items-center lg:flex-row" id='tokenomics'>
      <div className="w-full lg:w-1/2 md:order-1">
      <p className='font-bold text-xl leading-10 mb-2'>CA:</p>
      <p className='font-bold text-xl leading-10 mb-2'>Total Supply : 100 Million</p>
      <p className='text-base text-gray-400 leading-10 mb-2'>90% Liquidity</p>
      <p className='text-base text-gray-400 leading-10 mb-2'>5% CEX</p>
      <p className='text-base text-gray-400 leading-10 mb-2'>2% For GPU Expansion & Maintenance</p>
      <p className='text-base text-gray-400 leading-10 mb-2'>1% For Marketing</p>
      <p className='text-base text-gray-400 leading-10 mb-2'>2% Revenue Sharing Pool</p>
      <p className='font-bold text-xl leading-10 mb-2'>Tax : 0% BUY / SELL</p>
      <p className='text-base text-gray-400 leading-10 mb-2'>Our team operates on real revenue hence NO TEAM TOKENS</p>
      </div>
      <div className="w-full lg:w-1/2 my-16 justify-center md:order-2 bg-palette-color-5">
      <img
          src={SVG} 
          alt="Hero"
          className="px-8 object-cover"
        />
 </div>
    </div>
    </div>
  );
};

export default Chart;
