import React from 'react';
import LOGO from "../assets/logo.png";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='bg-palette-color-5 pt-32 p-8 text-center text-gray-400' id='socials'>
      <p className='text-palette-color-3 text-lg leading-loose'>SOCIALS</p>
      <h1 className='text-4xl leading-loose font-bold text-white'>JOIN OUR COMMUNITY</h1>

      <div className='flex justify-center items-center space-x-4 mt-8'>
        <Link to='https://x.com/nodeaieth'><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="90" width="90" xmlns="http://www.w3.org/2000/svg"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm215.3 337.7c.3 4.7.3 9.6.3 14.4 0 146.8-111.8 315.9-316.1 315.9-63 0-121.4-18.3-170.6-49.8 9 1 17.6 1.4 26.8 1.4 52 0 99.8-17.6 137.9-47.4-48.8-1-89.8-33-103.8-77 17.1 2.5 32.5 2.5 50.1-2a111 111 0 0 1-88.9-109v-1.4c14.7 8.3 32 13.4 50.1 14.1a111.13 111.13 0 0 1-49.5-92.4c0-20.7 5.4-39.6 15.1-56a315.28 315.28 0 0 0 229 116.1C492 353.1 548.4 292 616.2 292c32 0 60.8 13.4 81.1 35 25.1-4.7 49.1-14.1 70.5-26.7-8.3 25.7-25.7 47.4-48.8 61.1 22.4-2.4 44-8.6 64-17.3-15.1 22.2-34 41.9-55.7 57.6z"></path></svg>
        </Link>
        <Link to='https://t.me/NodeAIGPU '><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="70" width="70" xmlns="http://www.w3.org/2000/svg"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"></path></svg>
        </Link>
      </div>
        <img src={LOGO} alt='Logo' className='w-1/4 mt-10 mx-auto my-auto' />
        <div className='flex gap-4 text-base leading-loose font-bold justify-center items-center space-x-4 mt-8'>
            <a href=''>Getting Started</a>
            <a href='https://docs.nodeai.art/introduction/overview'>Docs</a>
            <a href=''>Terms of Service</a>
            <a href=''>Privacy Policy</a>
        </div>
        <p className='font-bold py-6 text-base'>Copyright © 2023. NODEAI</p>
    </div>
  );
};

export default Footer;
