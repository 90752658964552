import React, {useState, useEffect} from 'react';

const Gpunodes = () => {
    const calculateTimeLeft = () => {
        const difference = +new Date('2024-02-07') - +new Date();
        let timeLeft = {};
    
        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((difference % (1000 * 60)) / 1000),
          };
        }
    
        return timeLeft;
      };
    
      const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    
      useEffect(() => {
        const interval = setInterval(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
    
        return () => clearInterval(interval);
      }, []);
    return (
        <div className='bg-palette-color-1 p-8 lg:p-28 text-white'>
            <p className='text-palette-color-3 text-lg'>NODES</p>
            <h1 className='font-palette-font-1 text-4xl leading-10 font-bold mb-20'>LIVE GPU NODES</h1>
            <div className='hidden md:grid md:grid-cols-3 justify-items-center items-center gap-4 font-bold p-4 mb-8 '>
                <p className='leading-8 text-gray-400 font-bold'>GPU NODE</p>
                <p className='leading-8 text-gray-400 font-bold'>PER MONTH</p>
                <p className='leading-8 text-gray-400 font-bold'>RENT</p>
            </div>
            <div className='font-palette-font-1 group relative'>
                <div className='hidden md:flex md:flex-cols justify-between items-center font-bold p-8 mb-8 bg-palette-color-5 relative'>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>3 x RTX 4090</p>
                        <p className='text-gray-400'>3X24GB GPU, 2X6226R, 384GB RAM</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$2,600 / Month</p>
                    </div>
                    <div className='flex gap-4 w-60 items-center'>
                        <p className='px-14 py-3 transition duration-300 bg-palette-color-3 text-black '> {timeLeft.days > 0 ? (
        <p className="text-xl font-bold mb-2">{timeLeft.days}d:{timeLeft.hours}h:{timeLeft.minutes}m:{timeLeft.seconds}s</p>
      ) : (
        <p className="text-xl font-bold mb-2">Countdown expired</p>
      )}</p>
                    </div>
                    <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6 transition-all duration-300'></div>
                    <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
                </div>
            </div>

            <div className='group font-palette-font-1'>
                <div className='hidden md:flex md:flex-cols justify-between items-center font-bold p-8 mb-8 bg-palette-color-5 relative'>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>6 x RTX 3090</p>
                        <p className='text-gray-400'>6X24GB GPU, 2X6226R, 384GB RAM</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$3,200 / Month</p>
                    </div>
                    <div className='flex gap-4 w-60 items-center'>
                        <p className='px-14 py-3 transition duration-300 bg-palette-color-3 text-black '>{timeLeft.days > 0 ? (
        <p className="text-xl font-bold mb-2">{timeLeft.days}d:{timeLeft.hours}h:{timeLeft.minutes}m:{timeLeft.seconds}s</p>
      ) : (
        <p className="text-xl font-bold mb-2">Countdown expired</p>
      )}</p>
                    </div>
                    <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                    <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
                </div>
            </div>
            <div className='group font-palette-font-1'>
                <div className='hidden md:flex md:flex-cols justify-between items-center font-bold p-8 mb-8 bg-palette-color-5 relative'>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>4 x RTX 4090</p>
                        <p className='text-gray-400'>4X24GB GPU, 2X6226R, 384GB RAM</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$3,300 / Month</p>
                    </div>
                    <div className='flex gap-4 w-60 items-center'>
                        <p className='px-14 py-3 transition duration-300 bg-palette-color-3 text-black '>{timeLeft.days > 0 ? (
        <p className="text-xl font-bold mb-2"> {timeLeft.days}d:{timeLeft.hours}h:{timeLeft.minutes}m:{timeLeft.seconds}s</p>
      ) : (
        <p className="text-xl font-bold mb-2">Countdown expired</p>
      )}</p>
                    </div>
                    <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                    <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
                </div>
            </div>
            <div className='group'>
                <div className='hidden md:flex md:flex-cols justify-between items-center font-bold p-8 mb-8 bg-palette-color-5 relative'>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>8 x RTX 3090</p>
                        <p className='text-gray-400'>8X24GB GPU, 2X6226R, 384GB RAM</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$3,950 / Month</p>
                    </div>
                    <div className='flex gap-4 w-60 items-center'>
                        <p className='px-14 py-3 transition duration-300 bg-palette-color-3 text-black '>{timeLeft.days > 0 ? (
        <p className="text-xl font-bold mb-2">{timeLeft.days}d:{timeLeft.hours}h:{timeLeft.minutes}m:{timeLeft.seconds}s</p>
      ) : (
        <p className="text-xl font-bold mb-2">Countdown expired</p>
      )}</p>
                    </div>
                    <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                    <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
                </div>
            </div>
            <div className='group font-palette-font-1'>
                <div className='hidden md:flex md:flex-cols justify-between items-center font-bold p-8 mb-8 bg-palette-color-5 relative'>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>5 x RTX 3090</p>
                        <p className='text-gray-400'>5X24GB GPU, 2X6226R, 384GB RAM</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$2,700 / Month</p>
                    </div>
                    <div className='flex gap-4 w-60 items-center'>
                        <p className='px-14 py-3 transition duration-300 bg-palette-color-3 text-black '>{timeLeft.days > 0 ? (
        <p className="text-xl font-bold mb-2">{timeLeft.days}d:{timeLeft.hours}h:{timeLeft.minutes}m:{timeLeft.seconds}s</p>
      ) : (
        <p className="text-xl font-bold mb-2">Countdown expired</p>
      )}</p>
                    </div>
                    <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                    <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
                </div>
            </div>
            <div className='group relative font-palette-font-1'>
                <div className='flex gap-4 justify-between font-bold p-4 mb-8 bg-palette-color-4 md:hidden relative'>
                    <div className='text-gray-400 font-bold'>
                        <p className='leading-10'>GPU NODE:</p>
                        <p className='leading-10'>PER MONTH:</p>
                        <p className='leading-10'>RENT:</p>
                    </div>
                    <div className='text-left font-bold'>
                        <p className=''>3 x RTX 4090</p>
                        <p className='text-gray-400'>3X24GB GPU, 2X6226R, 384GB RAM</p>
                        <p className='leading-7'>$2,600 / Month</p>
                        <button className='mt-2 px-2 py-1 font-medium transition duration-300 bg-palette-color-3 text-black'>{timeLeft.days > 0 ? (
        <p className="text-xl font-bold mb-2">{timeLeft.days}d:{timeLeft.hours}h:{timeLeft.minutes}m:{timeLeft.seconds}s</p>
      ) : (
        <p className="text-xl font-bold mb-2">Countdown expired</p>
      )}</button>
                    </div>
                </div>
                <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
            </div>
            <div className='group relative'>
                <div className='flex gap-4 justify-between font-bold p-4 mb-8 bg-palette-color-4 md:hidden relative'>
                    <div className='text-gray-400 font-bold'>
                    <p className='leading-10'>GPU NODE:</p>
                        <p className='leading-10'>PER MONTH:</p>
                        <p className='leading-10'>RENT:</p>
                    </div>
                    <div className='text-left font-bold'>
                        <p className=''>6 x RTX 3090</p>
                        <p className='text-gray-400'>6X24GB GPU, 2X6226R, 384GB RAM</p>
                        <p className='leading-7'>$3,200 / Month</p>
                        <button className='mt-2 px-2 py-1 font-medium transition duration-300 bg-palette-color-3 text-black'>{timeLeft.days > 0 ? (
        <p className="text-xl font-bold mb-2"> {timeLeft.days}d:{timeLeft.hours}h:{timeLeft.minutes}m:{timeLeft.seconds}s</p>
      ) : (
        <p className="text-xl font-bold mb-2">Countdown expired</p>
      )}</button>
                    </div>
                    <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                    <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>

                </div>
            </div>
            <div className='group relative font-palette-font-1'>
                <div className='flex gap-4 justify-between font-bold p-4 mb-8 bg-palette-color-4 md:hidden relative'>
                    <div className='text-gray-400 font-bold'>
                    <p className='leading-10'>GPU NODE:</p>
                        <p className='leading-10'>PER MONTH:</p>
                        <p className='leading-10'>RENT:</p>
                    </div>
                    <div className='text-left font-bold'>
                    <p className=''>4 x RTX 4090</p>
                        <p className='text-gray-400'>4X24GB GPU, 2X6226R, 384GB RAM</p>
                        <p className='leading-7'>$3,300 / Month</p>
                        <button className='mt-2 px-2 py-1 font-medium transition duration-300 bg-palette-color-3 text-black'>{timeLeft.days > 0 ? (
        <p className="text-xl font-bold mb-2">{timeLeft.days}d:{timeLeft.hours}h:{timeLeft.minutes}m:{timeLeft.seconds}s</p>
      ) : (
        <p className="text-xl font-bold mb-2">Countdown expired</p>
      )}</button>
                    </div>
                </div>
                <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
            </div>
            <div className='group relative font-palette-font-1'>
                <div className='flex gap-4 justify-between font-bold p-4 mb-8 bg-palette-color-4 md:hidden relative'>
                    <div className='text-gray-400 font-bold'>
                    <p className='leading-10'>GPU NODE:</p>
                        <p className='leading-10'>PER MONTH:</p>
                        <p className='leading-10'>RENT:</p>
                    </div>
                    <div className='text-left font-bold'>
                    <p className=''>8 x RTX 3090</p>
                        <p className='text-gray-400'>8X24GB GPU, 2X6226R, 384GB RAM</p>
                        <p className='leading-7'>$3,950 / Month</p>
                        <button className='mt-2 px-2 py-1 font-medium transition duration-300 bg-palette-color-3 text-black'>{timeLeft.days > 0 ? (
        <p className="text-xl font-bold mb-2">{timeLeft.days}d:{timeLeft.hours}h:{timeLeft.minutes}m:{timeLeft.seconds}s</p>
      ) : (
        <p className="text-xl font-bold mb-2">Countdown expired</p>
      )}</button>
                    </div>
                </div>
                <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
            </div>
            <div className='group relative font-palette-font-1'>
                <div className='flex gap-4 justify-between font-bold p-4 mb-8 bg-palette-color-4 md:hidden relative'>
                    <div className='text-gray-400 font-bold'>
                    <p className='leading-10'>GPU NODE:</p>
                        <p className='leading-10'>PER MONTH:</p>
                        <p className='leading-10'>RENT:</p>
                    </div>
                    <div className='text-left font-bold'>
                    <p className=''>5 x RTX 3090</p>
                        <p className='text-gray-400'>5X24GB GPU, 2X6226R, 384GB RAM</p>
                        <p className='leading-7'>$2,700 / Month</p>
                        <button className='mt-2 px-2 py-1 font-medium transition duration-300 bg-palette-color-3 text-black'>{timeLeft.days > 0 ? (
        <p className="text-xl font-bold mb-2">{timeLeft.days}d:{timeLeft.hours}h:{timeLeft.minutes}m:{timeLeft.seconds}s</p>
      ) : (
        <p className="text-xl font-bold mb-2">Countdown expired</p>
      )}</button>
                    </div>
                </div>
                <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
            </div>
        </div>
    );
};

export default Gpunodes;
