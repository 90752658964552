import React from 'react';
import building1 from "../assets/building.png";
import building2 from "../assets/building2.png";
import one from "../assets/one.svg";
import two from "../assets/two.svg";
import three from "../assets/three.svg";
import { Link } from 'react-router-dom';

const Steps = () => {
  return (
    <div className='relative bg-palette-color-4 p-10 pt-24 lg:p-40 text-white' style={{ overflow: 'hidden' }}>
      <div className='flex flex-col lg:flex-row z-10 mb-32'>
        <div className="lg:w-1/2 md:order-1 mb-4 relative">
          <p className='text-palette-color-3 text-lg'>3 EASY STEPS</p>
          <h1 className='text-4xl leading-loose font-bold mb-12'>HOW TO PARTICIPATE</h1>
          <p className='text-base text-gray-400 leading-loose font-bold'>Here are the step-by-step instructions to participate in our revenue-sharing ecosystem.</p>
          <Link to="/stake"><button className="px-14 py-4 mt-8 text-lg transition duration-300 border border-gray-200 text-white">EARN {'>'}</button></Link>
        </div>
        <div className='lg:w-1/2 md:order-2 relative'>
          <div className='items-start justify-start p-4 mb-8 bg-palette-color-7'>
            <div className='flex gap-4'>
              <img src={one} alt="one" className='p-4' />
              <div className='p-4'>
                <h1 className='font-bold text-xl'>BUY $GPU</h1>
                <p className='text-gray-400 font-bold'>Copy the contract address, go to Uniswap and swap ETH to GPU</p>
              </div>
            </div>
          </div>
          <div className='items-start justify-start p-4 mb-8 bg-palette-color-7'>
            <div className='flex gap-4'>
              <img src={two} alt="two" className='p-4' />
              <div className='p-4'>
                <h1 className='font-bold text-xl'>GO TO NODEAI DAPP</h1>
                <p className='text-gray-400 font-bold'>Visit the Node AI Dapp and view the staking pool</p>
              </div>
            </div>
          </div>
          <div className='items-start justify-start p-4 bg-palette-color-7' >
            <div className='flex gap-4'>
              <img src={three} alt="three" className='p-4' />
              <div className='p-4'>
                <h1 className='font-bold text-xl'>STAKE $GPU AND EARN $ETH</h1>
                <p className='text-gray-400 font-bold'>Stake your $GPU tokens and earn a share of the monthly revenue</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={building1} alt='building1' className='absolute z-0 top-8 lg:ml-96 align-middle' />
    <img src={building2} alt='building2' className='absolute left-8 lg:left-20' style={{bottom:"-106px"}}/>
    </div>
  );
};

export default Steps;
