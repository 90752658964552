
import React from 'react';
import SVG from "../assets/hero.gif"
import NVIDIA from "../assets/nvidia.png"
import AMD from "../assets/amd.png"
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className="h-full pt-20 flex flex-col items-center md:flex-row p-16 text-white">
      <div className="md:w-1/2 pt-20 md:pl-24 pl-4 md:order-1">
        <h1 className="text-5xl font-bold">POWERING THE <br/>DEMAND FOR{' '}
          <span className='text-palette-color-3 leading-normal'>
            NEXT<br/> GEN AI
          </span>
        </h1>
        <p className="text-xl mt-8 font-semibold leading-normal">
          Harness the power of decentralized AI with Node AI
        </p>
        <div className='flex  gap-8 columns-2'>
          <Link to="/nodes"><button className="p-4 lg:px-14 lg:py-4 mt-8 text-sm lg:text-lg transition duration-300 bg-palette-color-3 text-black">VIEW NODES</button></Link>
          <Link to="https://www.mexc.com/exchange/GPU_USDT"><button className="p-4 lg:px-14 lg:py-4 mt-8 text-sm lg:text-lg transition duration-300 border border-gray text-white">BUY $GPU</button></Link>
        </div>
        <p className="text-xl mt-16 font-semibold leading-normal">KEY INFRASTRUCTURE PROVIDERS</p>
        <div className='flex flex-nowrap items-center justify-start'>
        <img src={NVIDIA} alt="token logo" class="h-auto w-1/2" />
        <img src={AMD} alt="token logo" class="w-1/2 h-auto" />
        </div>
      </div>
      <div className="w-full flex md:w-1/2 items-center justify-center md:order-2">
        <div className='svg-animation'>

      <img
          src={SVG}
          alt="Hero"
          className="h-96 "
          />
          </div>
 </div>
    </div>
  );
};

export default Hero;
