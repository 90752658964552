import React from 'react';

const Revenue = () => {
    return (
        <div className='bg-palette-color-1 p-8 lg:p-40 text-white' id='pricing'>
            <p className='text-palette-color-3 text-lg'>REVENUE</p>
            <h1 className='font-palette-font-1 text-4xl leading-10 font-bold mb-20'>GPU PRICING</h1>
            <div className='hidden md:grid md:grid-cols-3 gap-4 font-bold p-4 mb-8 text-left'>
                <p className='leading-8 text-gray-400 font-bold'>GRAPHICS CARD</p>
                <p className='leading-8 text-gray-400 font-bold'>PER HOUR</p>
                <p className='leading-8 text-gray-400 font-bold'>AVG MONTHLY</p>
            </div>
            <div className='font-palette-font-1 group relative'>
                <div className='hidden md:grid md:grid-cols-3 gap-4 font-bold p-5 mb-8 bg-palette-color-5 relative'>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>A100</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$0.85 / hr</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$ 600</p>
                    </div>
                    <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6 transition-all duration-300'></div>
                    <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
                </div>
            </div>

            <div className='group font-palette-font-1'>
                <div className='hidden md:grid md:grid-cols-3 gap-4 font-bold p-5 mb-8 bg-palette-color-5 relative'>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>A6000 / A40</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$0.44 / hr</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$ 300</p>
                    </div>
                    <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                    <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
                </div>
            </div>
            <div className='group font-palette-font-1'>
                <div className='hidden md:grid md:grid-cols-3 gap-4 font-bold p-5 mb-8 bg-palette-color-5 relative'>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>GeForce 4090</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$0.47 / hr</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$ 200</p>
                    </div>
                    <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                    <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
                </div>
            </div>
            <div className='group'>
                <div className='hidden md:grid md:grid-cols-3 gap-4 font-bold p-5 mb-8 bg-palette-color-5 relative'>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>GeForce 3090</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$0.20 / hr</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$ 150</p>
                    </div>
                    <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                    <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
                </div>
            </div>
            <div className='group font-palette-font-1'>
                <div className='hidden md:grid md:grid-cols-3 gap-4 font-bold p-5 mb-8 bg-palette-color-5 relative'>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>A5000 / A10</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$0.22 / hr</p>
                    </div>
                    <div className='text-left'>
                        <p className='text-xl font-bold'>$ 125</p>
                    </div>
                    <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                    <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
                </div>
            </div>
            <div className='group relative font-palette-font-1'>
                <div className='flex gap-4 justify-between font-bold p-4 mb-8 bg-palette-color-4 md:hidden relative'>
                    <div className='text-gray-400 font-bold'>
                        <p className='leading-10'>TIERS:</p>
                        <p className='leading-10'>hourly:</p>
                        <p className='leading-10'>monthly</p>
                    </div>
                    <div className='text-left font-bold'>
                        <p className='leading-10'>A100</p>
                        <p className='leading-10'>$0.85 / hr</p>
                        <p className='leading-10'>$ 600</p>
                    </div>
                </div>
                <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
            </div>
            <div className='group relative'>
                <div className='flex gap-4 justify-between font-bold p-4 mb-8 bg-palette-color-4 md:hidden relative'>
                    <div className='text-gray-400 font-bold'>
                        <p className='leading-10'>TIERS:</p>
                        <p className='leading-10'>hourly:</p>
                        <p className='leading-10'>monthly</p>
                    </div>
                    <div className='text-left font-bold'>
                        <p className='leading-10'>A6000 / A40</p>
                        <p className='leading-10'>$0.44 / hr</p>
                        <p className='leading-10'>$ 300</p>
                    </div>
                    <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                    <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>

                </div>
            </div>
            <div className='group relative font-palette-font-1'>
                <div className='flex gap-4 justify-between font-bold p-4 mb-8 bg-palette-color-4 md:hidden relative'>
                    <div className='text-gray-400 font-bold'>
                        <p className='leading-10'>TIERS:</p>
                        <p className='leading-10'>hourly:</p>
                        <p className='leading-10'>monthly</p>
                    </div>
                    <div className='text-left font-bold'>
                        <p className='leading-10'>GeForce 4090</p>
                        <p className='leading-10'>$0.47 / hr</p>
                        <p className='leading-10'>$ 200</p>
                    </div>
                </div>
                <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
            </div>
            <div className='group relative font-palette-font-1'>
                <div className='flex gap-4 justify-between font-bold p-4 mb-8 bg-palette-color-4 md:hidden relative'>
                    <div className='text-gray-400 font-bold'>
                        <p className='leading-10'>TIERS:</p>
                        <p className='leading-10'>hourly:</p>
                        <p className='leading-10'>monthly</p>
                    </div>
                    <div className='text-left font-bold'>
                        <p className='leading-10'>GeForce 3090</p>
                        <p className='leading-10'>$0.20 / hr</p>
                        <p className='leading-10'>$ 150</p>
                    </div>
                </div>
                <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
            </div>
            <div className='group relative font-palette-font-1'>
                <div className='flex gap-4 justify-between font-bold p-4 mb-8 bg-palette-color-4 md:hidden relative'>
                    <div className='text-gray-400 font-bold'>
                        <p className='leading-10'>TIERS:</p>
                        <p className='leading-10'>hourly:</p>
                        <p className='leading-10'>monthly</p>
                    </div>
                    <div className='text-left font-bold'>
                        <p className='leading-10'>A5000 / A10</p>
                        <p className='leading-10'>$0.22 / hr</p>
                        <p className='leading-10'>$ 125</p>
                    </div>
                </div>
                <div className='absolute top-0 left-0 w-1/5 h-full group-hover:border-t-4 group-hover:border-l-4 group-hover:border-palette-color-6'></div>
                <div className='absolute bottom-0 right-0 w-1/5 h-full group-hover:border-b-4 group-hover:border-r-4 group-hover:border-green-400 transition-all duration-300'></div>
            </div>
        </div>
    );
};

export default Revenue;
