import React, {useState} from 'react';

const Faq = () => {
  const faqData = [
    {
      question: 'What is Node AI?',
      answer: 'NODEAI is a decentralized GPU & AI ecosystem which allows users to benefit from is its unique features.',
    },
    {
      question: 'How we can buy $GPU?',
      answer: 'Click to enter through the official link, or search $GPU in MEXC.',
    },
    {
        question: 'How secure is a GPU Node?',
        answer: 'We use industry security standards to ensure privacy of your data. Once you setup a node the team will not have access to your data.',
      },
      {
        question: 'How can I access my Node ?',
        answer: 'After finilizing your requirements & making payment, we will give you ssh access to your GPU node.',
      },
      {
        question: 'How much will I earn by staking $GPU ?',
        answer: 'You will earn rewards on a monthly basis. The staking pool will be limited to benefit early supporters and maximize distributed rewards per staker.',
      }
    ];
    const [openIndex, setOpenIndex] = useState(null);

    const handleAccordionClick = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className='pt-32 p-8 text-center text-gray-400' id='faq'>
          <p className='text-palette-color-3 text-lg leading-loose'>QUESTIONS & ANSWERS</p>
          <h1 className='text-4xl leading-loose font-bold text-white'>FREQUENTLY ASKED QUESTIONS</h1>
    
          <div className='md:mx-64 md:my-8 text-left p-10 bg-palette-color-5'>
            {faqData.map((item, index) => (
              <div key={index} className='mb-4'>
                <div
                  className='cursor-pointer flex justify-between items-center text-sm lg:text-xl font-semibold text-gray-400 hover:text-white py-2'
                  onClick={() => handleAccordionClick(index)}
                >
                  <span>{item.question}</span>
                  <p>{openIndex === index ? '-' : '+'}</p>
                </div>
                <div className='border border-gray-400'></div>
                {openIndex === index && (
                  <p className='text-gray-300 text-left mt-2'>{item.answer}</p>
                )}
              </div>
            ))}
          </div>
          <p className='text-white font-medium'>Didn’t get your answer ? <span className='text-palette-color-3'>Ask Here !</span></p>
        </div>
      );
    };
    
    export default Faq;
