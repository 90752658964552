import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from './pages/main';
import "./styles/global.css"
import Node from './pages/node';
import Stake from './pages/stake';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          
        <Route path="/" element={<Main />} />
        <Route path="/nodes" element={<Node/> } />        
        <Route path="/stake" element={<Stake/> } />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
