
import Chart from "../components/chart";
import Faq from "../components/faq";
import Features from "../components/features";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Revenue from "../components/revenue";
import Tokenomics from "../components/tokenomics";
import Roadmap from "../components/roadmap"
import Steps from "../components/steps";
import Navbar from "../components/navbar";

const Main = () => {
    return(
        <div className="bg-palette-color-1">
            <Navbar/>
            <Hero/>
            <Tokenomics />
            <Features />
            <Revenue />
            <Steps />
            <Roadmap/>
            <Chart />
            <Faq />
            <Footer />
        </div>
    )
}

export default Main;