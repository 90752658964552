import React from 'react';

const Roadmap = () => {
    return (
        <div className='p-8 lg:p-40 text-white z-20' id='roadmap'>
            <p className='text-palette-color-3 text-lg'>OUR GOALS</p>
            <h1 className='text-4xl leading-10 font-bold mb-28'>ROADMAP</h1>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-8 font-bold'>
                <div className='relative text-left'>

                    <p className='text-2xl font-bold relative z-10 leading-10'>STAGE 01</p>
                    <div className='flex items-center'>
                        <div className='mr-4'>
                            <div className='relative'>
                                <div className='w-8 h-8 bg-green rounded-full -mt-0 bg-palette-color-3 absolute top-0 left-1/2 transform -translate-x-1/2 flex items-center justify-center'>
                                    <svg
                                        className='text-palette-color-3 w-5 h-5'
                                        fill='none'
                                        stroke='black'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='3'
                                        viewBox='0 0 24 24'
                                    >
                                        <path d='M20 6L9 17l-5-5'></path>
                                    </svg>
                                </div>
                            </div>
                            <div className='h-40 border-gray-400 border-dashed border-l-2 mt-8'></div>
                        </div>

                        <ul className='text-gray-400 mt-4'>
                            <li className='flex items-center space-x-2 leading-10'>
                                <svg
                                    className='text-gray-500 w-4 h-4'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    viewBox='0 0 24 24'
                                >
                                    <path d='M20 6L9 17l-5-5'></path>
                                </svg>

                                <span>Launch NodeAI Website & Dapp</span>
                            </li>
                            {/* <li className='flex items-center space-x-2 leading-10'>
                                <svg
                                    className='text-gray-500 w-4 h-4'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    viewBox='0 0 24 24'
                                >
                                    <path d='M20 6L9 17l-5-5'></path>
                                </svg>


                                <span>$GPU Fair Launch</span>
                            </li> */}
                            <li className='flex items-center space-x-2 leading-10'>
                                <svg
                                    className='text-gray-500 w-4 h-2'
                                    fill='currentColor'
                                    viewBox='0 0 24 1'
                                >
                                    <rect width='24' height='4' rx='0.5'></rect>
                                </svg>

                                <span>Stake to Earn Rewards</span>
                            </li>
                            <li className='flex items-center space-x-2 leading-10'>
                                <svg
                                    className='text-gray-500 w-4 h-2'
                                    fill='currentColor'
                                    viewBox='0 0 24 1'
                                >
                                    <rect width='24' height='4' rx='0.5'></rect>
                                </svg>

                                <span>Build & Grow Community</span>
                            </li>
                            <li className='flex items-center space-x-2 leading-10'>
                                <svg
                                    className='text-gray-500 w-4 h-2'
                                    fill='currentColor'
                                    viewBox='0 0 24 1'
                                >
                                    <rect width='24' height='4' rx='0.5'></rect>
                                </svg>

                                <span>Referral Program</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='relative text-left'>

                    <p className='text-2xl font-bold relative z-10 leading-10'>STAGE 02</p>
                    <div className='flex items-center'>
                        <div className='mr-4'>
                            <div className='relative'>
                                <div className='w-8 h-8 bg-gray-400 rounded-full -mt-0 absolute top-0 left-1/2 transform -translate-x-1/2 flex items-center justify-center'>

                                </div>
                            </div>
                            <div className='h-40 border-gray-400 border-dashed border-l-2 mt-8'></div>
                        </div>

                        <ul className='text-gray-400 mt-4'>
                            <li className='flex items-center space-x-2 leading-10'>
                                <svg
                                    className='text-gray-500 w-4 h-2'
                                    fill='currentColor'
                                    viewBox='0 0 24 1'
                                >
                                    <rect width='24' height='4' rx='0.5'></rect>
                                </svg>

                                <span>Launch Enterprise API Endpoints</span>
                            </li>
                            <li className='flex items-center space-x-2 leading-10'>
                                <svg
                                    className='text-gray-500 w-4 h-2'
                                    fill='currentColor'
                                    viewBox='0 0 24 1'
                                >
                                    <rect width='24' height='4' rx='0.5'></rect>
                                </svg>

                                <span>Expand GPU Servers Phase 01</span>
                            </li>
                            <li className='flex items-center space-x-2 leading-10'>

                                <svg
                                    className='text-gray-500 w-4 h-2'
                                    fill='currentColor'
                                    viewBox='0 0 24 1'
                                >
                                    <rect width='24' height='4' rx='0.5'></rect>
                                </svg>

                                <span>Rent AI Nodes</span>
                            </li>
                            <li className='flex items-center space-x-2 leading-10'>
                                <svg
                                    className='text-gray-500 w-4 h-2'
                                    fill='currentColor'
                                    viewBox='0 0 24 1'
                                >
                                    <rect width='24' height='4' rx='0.5'></rect>
                                </svg>

                                <span>AI Trading Tools</span>
                            </li>
                            <li className='flex items-center space-x-2 leading-10'>
                                <svg
                                    className='text-gray-500 w-4 h-2'
                                    fill='currentColor'
                                    viewBox='0 0 24 1'
                                >
                                    <rect width='24' height='4' rx='0.5'></rect>
                                </svg>

                                <span>7 Figs Yearly Revenue Target</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='relative text-left'>

                    <p className='text-2xl font-bold relative z-10 leading-10'>STAGE 03</p>
                    <div className='flex items-center'>
                    <div className='mr-4'>
                            <div className='relative'>
                                <div className='w-8 h-8 bg-gray-400 rounded-full -mt-0 absolute top-0 left-1/2 transform -translate-x-1/2 flex items-center justify-center'>

                                </div>
                            </div>
                            <div className='h-40 border-gray-400 border-dashed border-l-2 mt-8'></div>
                        </div>

                        <ul className='text-gray-400 mt-4'>
                            <li className='flex items-center space-x-2 leading-10'>
                                <svg
                                    className='text-gray-500 w-4 h-2'
                                    fill='currentColor'
                                    viewBox='0 0 24 1'
                                >
                                    <rect width='24' height='4' rx='0.5'></rect>
                                </svg>

                                <span>Expand GPU Servers Phase 02</span>
                            </li>
                            <li className='flex items-center space-x-2 leading-10'>
                                <svg
                                    className='text-gray-500 w-4 h-2'
                                    fill='currentColor'
                                    viewBox='0 0 24 1'
                                >
                                    <rect width='24' height='4' rx='0.5'></rect>
                                </svg>

                                <span>Lend GPU Power & Earn</span>
                            </li>
                            <li className='flex items-center space-x-2 leading-10'>
                                <svg
                                    className='text-gray-500 w-4 h-2'
                                    fill='currentColor'
                                    viewBox='0 0 24 1'
                                >
                                    <rect width='24' height='4' rx='0.5'></rect>
                                </svg>

                                <span>On Demand AI Nodes</span>
                            </li>
                            <li className='flex items-center space-x-2 leading-10'>
                                <svg
                                    className='text-gray-500 w-4 h-2'
                                    fill='currentColor'
                                    viewBox='0 0 24 1'
                                >
                                    <rect width='24' height='4' rx='0.5'></rect>
                                </svg>


                                <span>More Developments TBA</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roadmap;
