import react from 'react';
import Stake1 from "../assets/stake1.png"
import Stake2 from "../assets/stake2.png"
import Stake3 from "../assets/stake3.png"

const Stakes =()=>{
    return(
        <div className='flex flex-wrap justify-around mt-10 mx-6'>
            <div className='w-full lg:w-2/5 m-12'>
                <div className='bg-palette-color-5 flex flex-col h-full p-12'>
                    <p className='text-palette-color-3 text-xl font-bold'>STAKE $GPU & EARN</p>
                    <hr className='mt-8 border-gray-400'></hr>
                    <p className='leading-10 pt-6 text-gray-400 text-md font-bold'>Total Staked:<span className='text-white font-normal'> 0 GPU</span></p>
                    <p className='leading-10 text-gray-400 text-md font-bold'>Lock period:<span className='text-white font-normal'> 30 days</span></p>
                    <p className='leading-10 text-gray-400 text-md font-bold'>Early unstake fee:<span className='text-white font-normal'> 25%</span></p>
                    <p className='leading-10 text-gray-400 text-md font-bold'>Max Stake:<span className='text-white font-normal'> 10000 GPU</span></p>
                    <p className='leading-10 pt-6 text-white text-md font-bold'>Balance: 0 GPU</p>
                    <div className='flex justify-between'>
                        <input className='border border-gray-400 bg-transparent p-4' placeholder='0.00'/>
                        <button className='bg-palette-color-3 px-10'>APPROVE</button>
                    </div>
                    <p className='leading-10 pt-6 text-white text-md font-bold'>Staked: 0 GPU</p>
                    <div className='flex justify-between'>
                        <input className='border border-gray-400 bg-transparent p-4' placeholder='0.00'/>
                        <button className='bg-palette-color-3 px-8'>WITHDRAW</button>
                    </div>
                </div>
            </div>
            <div className='w-full lg:w-2/5 h-full m-12'>
                <div className='bg-palette-color-5 p-12 flex justify-between mb-8'>
                    <div>
                        <p className='leading-10 text-white text-xl font-bold'>0 ETH</p>
                        <p className='leading-10 text-gray-400 text-md font-medium'>My ETH Earnings</p>
                    </div>
                    <div>
                        <img src={Stake1} alt='stake' />
                    </div>
                </div>
                <div className='bg-palette-color-5 p-12 flex justify-between mb-8'>
                    <div>
                        <p className='leading-10 text-white text-xl font-bold'>$15,750</p>
                        <p className='leading-10 text-gray-400 text-md font-medium'>Monthly Node Revenue</p>
                        <p className='leading-10 flex items-center gap-1 text-white hover:text-palette-color-3 text-md font-medium'><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="20" width="20" xmlns="http://www.w3.org/2000/svg" ><path d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z"></path></svg>Go to revenue pool</p>
                    </div>
                    <div>
                        <img src={Stake2} alt='stake' />
                    </div>
                </div>
                <div className='bg-palette-color-5 p-12 flex justify-between'>
                    <div>
                        <p className='leading-10 text-white text-xl font-bold'>0</p>
                        <p className='leading-10 text-gray-400 text-md font-medium'>Number of Stakers</p>
                    </div>
                    <div>
                        <img src={Stake3} alt='stake' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stakes