
import Gpunodes from "../components/gpunodes";
import Navbar2 from "../components/navbar2";

const Node = () => {
    return(
        <div className="bg-palette-color-1">
            <Navbar2/>
            <Gpunodes />
        </div>
    )
}

export default Node;